import React from 'react'
import { useClasses } from '../lib/ReactUtils'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import CTA from '../components/CTA'
import '../styles/Hero'
import '../styles/TwoCols'
import '../styles/Prose'
import BackgroundImage from 'gatsby-background-image'
import { useStaticQuery, graphql } from "gatsby"

import DOCS from '../images/training/docs.svg'
import TRAINING1 from '../images/training/training1.svg'
import TRAINING2 from '../images/training/training2.svg'
import ADMIN from '../images/training/admin.svg'



export default function TrainingPage() {
  const root = useClasses()
  const { heroImage } = useStaticQuery(
    graphql`
      query {
        heroImage: file(
          absolutePath: { glob: "**/images/training/hero.jpg" }
          sourceInstanceName: { eq: "images" }   
        ) {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 50) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Layout full dark>
      <SEO title='Training' image={heroImage.childImageSharp.fluid.src}/>
      <main className='main' ref={root}>
        <BackgroundImage className='hero-container' fluid={heroImage.childImageSharp.fluid}>
          <div className='hero-overlay'/>
          <div className='hero-inner'>
            <div className='hero-text'>
              <div className='hero-main'>We Got You Covered ...</div>
              <div className='hero-slanted'>Hands-on Training</div>
            </div>
          </div>
        </BackgroundImage>

      <div className='container'>
        <div className="heading-separator"/>
        <h1 className="title">Training</h1>
        <h2 className="subtitle">To Unleash Your 'Digital'</h2>
        <div className="description">
          <p>OneOffice was built to be super-easy to learn quickly, even for non-techies. Yet, any new tool can be daunting at first.</p>
          <p>We are here with <b>hands-on training</b>, <b>docs</b> and <b>videos</b> to unleash your team's power!</p>
        </div>

          <div className="twocols">
            <div className="twocols-col-image">
              <img src={DOCS}/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">Docs</div>
              <a href='https://docs.oneoffice.ca' className="basic heading">docs.oneoffice.ca</a>
              <div className="description">
                <p>Our <a href='https://docs.oneoffice.ca' target='_blank'>docs site</a> was designed to be easy to traverse to find what you need. You'll find the link at the top and bottom of every page.</p>
                <p>It doesn't cover everything, by design. It gets you started in the right direction, the intuitive User Interface will do the rest!</p>
                <p className='quote'>If you haven't already visited the <a href='https://docs.oneoffice.ca' target='_blank'>docs site</a>, give it a spin. It's so simple, you'll hit the ground running in no time.</p>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <img src={TRAINING1}/>
            </div>
            <div className="twocols-col-text-right">
              <div className="subheading">#OO1 - Overview Training</div>
              <div className="heading">Back to Basics</div>
              <div className="description">
                <p>
                  The first-level training is a hands-on one to two-hour training, which can be administered over video-conference.
                  This training goes through the built-in tools of OneOffice, so you have working knowlege of the system to start working right away.
                </p>
                <div className="prose sm:flex gap-x-2 mt-3">
                  <div className="sm:w-1/2">
                    <ul>
                      <li>Login</li>
                      <li>Dashboard</li>
                      <li>Email</li>
                      <li>Calendar</li>
                      <li>Contacts</li>
                    </ul>
                  </div>
                  <div className="sm:w-1/2">
                    <ul className='mt-0'>
                      <li>Files & Sharing</li>
                      <li>Document Editor</li>
                      <li>Deck (Kanban PM)</li>
                      <li>Calls</li>
                      <li>Chats & Comments</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div className="twocols">
            <div className="twocols-col-image">
              <img src={TRAINING2}/>
            </div>
            <div className="twocols-col-text-left">
              <div className="subheading">#OO2 - Advanced Training</div>
              <div className="heading">A Collaboration Powerhouse</div>
              <div className="description">
                <p className='quote'>
                  While the prior traning was focused on tools, this optional advanced training covers next-generation collaboration workflows.
                </p>
                <p>
                  You will learn how to create collaborative workspaces, share files with your colleagues, create projects with chats and communications.
                </p>
                <p>
                  You will also learn how to secure external collaboration and ensure your interactions with customers and suppliers is smooth and efficient.
                </p>
              </div>
            </div>
          </div>



          <div className="twocols">
            <div className="twocols-col-image">
              <img src={ADMIN}/>
            </div>
            <div className="twocols-col-text-right">
              <div className="subheading">#OOA1 - Admin Training</div>
              <div className="heading">You are back in Control</div>
              <div className="description">
                <p>
                  Administration of OneOffice is fluid and simple. User access is administered via groups (e.g. HR, Sales etc.), where a group admin can be any user.
                </p>
                <p>
                  This 1-2 hour training is typically for IT or office managers, so they can create new users, reset passwords, update groups etc.
                </p>
                <p>
                  In addition, for business and enterprise customers with their own servers, you will get full control over all the internal functionalities of OneOffice.
                </p>
              </div>
            </div>
          </div>


          <CTA variant={3}/>
        </div>
      </main>
    </Layout>
  )
}
